import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import HeaderStrip from "../../../components/Common/HeaderStrip";
import Pagination from "../../../components/Common/Pagination";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const headerData = [
  {
    id: 1,
    color: "#057D54",
    label: "Ongoing",
    status: "ongoing",
  },
  {
    id: 2,
    color: "#057D54",
    label: "Completed",
    status: "complete",
  },
  {
    id: 3,
    color: "#DC3545",
    label: "On Hold",
    status: "hold",
  },
  {
    id: 4,
    label: "All",
    status: "all",
  },
];

const Card = (props) => {
  const project = props.project;
  const [techNames, setTechNames] = useState([]);
  useEffect(() => {
    if (project) {
      const getName = (item) => item.techId?.name || item.name;
      const techNames = [
        ...project.technologies.client.map((item) => getName(item)),
        ...project.technologies.server.map((item) => getName(item)),
      ];
      setTechNames(techNames);
    }
  }, [project]);

  return (
    <div className="w-auto relative">
      {typeof project.technologies.client[0]?.techId === "string" &&
        headerData &&
        headerData
          .filter((item) => item.status === project.status)
          .map((item, index) => (
            <div
              style={{ backgroundColor: item.color }}
              className={`absolute   right-0 top-0  rounded-bl-lg rounded-tr-xl px-6 py-2 font-normal`}
            >
              {/* <p className='text-xs text-gray-400'>Status</p> */}
              <p className="text-xs text-white">{item.label}</p>
            </div>
          ))}
      <div className="w-auto p-6 border rounded-xl h-full flex flex-col justify-between">
        <div>
          <p className="text-xs font-light leading-3 pb-2 truncate w-full">
            Project Name
          </p>
          <p className="text-xl font-medium leading-6 pb-6">{project?.name}</p>
        </div>
        <div>
          {" "}
          <p className="text-xs font-light leading-3 pb-2">Project Type</p>
          <p className="text-sm leading-3 font-medium pb-6">
            {project.platform === "web" ? "Web App" : "Mobile App"}
          </p>
        </div>
        <div>
          <p className="text-xs font-light leading-3 pb-2">client Name</p>
          <p className="text-sm leading-3 font-medium pb-6">
            {" "}
            {project.client ? project.client.name : "-"}
          </p>
        </div>
        <div>
          <p className="text-xs font-light leading-3 pb-2">
            Start Date - End Date
          </p>
          <p className="text-sm leading-3 font-medium mb-6">
            {" "}
            <span className="mr-2">
              {project.start_date
                ? moment(project.start_date).format("ll")
                : "-"}
            </span>
            -
            <span className="ml-2">
              {project.end_date
                ? moment(project.end_date).format("ll")
                : "Present"}
            </span>
          </p>
        </div>
        <div>
          {" "}
          <p className="text-xs font-light leading-3 mb-4">Technologies</p>
          <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-2 w-fit">
            {techNames?.map((tech, index) => {
              return (
                <button className="bg-[#F2FAF4] px-2 py-[1px]  text-xs rounded-md text-[#057D54] w-fit">
                  {tech}
                </button>
              );
            })}
          </div>
        </div>
        <div>
          <Link to={`/projects/${project.name}/overview`}>
            <p className="flex gap-2 text-[#28A745] text-sm font-normal items-center mt-8 cursor-pointer">
              Go to Project{" "}
              <svg
                width="16"
                height="9"
                viewBox="0 0 16 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.5 5H3.5C3.22 5 3 4.78 3 4.5C3 4.22 3.22 4 3.5 4H12.5C12.78 4 13 4.22 13 4.5C13 4.78 12.78 5 12.5 5Z"
                  fill="#28A745"
                />
                <path
                  d="M10 8.49998C9.93442 8.50078 9.86941 8.48785 9.80913 8.46201C9.74885 8.43618 9.69465 8.39802 9.65 8.34998C9.45 8.14998 9.45 7.83998 9.65 7.63998L12.8 4.48998L9.65 1.33998C9.45 1.13998 9.45 0.82998 9.65 0.62998C9.85 0.42998 10.16 0.42998 10.36 0.62998L13.86 4.12998C14.06 4.32998 14.06 4.63998 13.86 4.83998L10.36 8.33998C10.26 8.43998 10.13 8.48998 10.01 8.48998L10 8.49998Z"
                  fill="#28A745"
                />
              </svg>
            </p>
          </Link>
        </div>
      </div>
    </div>
  );
};

const Project = () => {
  const [dataStatus, setStatus] = useState("ongoing");
  const [projectSearchValue, setProjectSearchValue] = useState("");
  const [filterDate, setFilterDate] = useState([]);
  const [selectDate, setSelectDate] = useState("all");
  const [projectListData, setProjectListData] = useState([]);
  const [searchData, setSearchData] = useState();
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [userProjects, setUserProjects] = useState([]);
  const dispatch = useDispatch();
  const projectsData = useSelector((state) => state.projects.projects);
  const projectsTotal = useSelector((state) => state.projects.projectsTotal);
  const pageLimit = 12;
  const pageCount = Math.ceil(projectsTotal / pageLimit);
  const usersData = useSelector((state) => state.auth.userInfoData);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    //cleanup function
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getListData = async () => {
    setLoading(true);
    setCurrentPage(0);
    await dispatch.projects.listProjectData({
      limit: pageLimit,
      currentPage: 0,
      status: dataStatus,
      search: projectSearchValue,
    });
    setLoading(false);
  };

  useEffect(() => {
    getListData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataStatus]);

  function getStatusClass(status) {
    let statusClass = "";
    switch (status) {
      case "ongoing":
        statusClass = "bg-primary-600";
        break;
      case "hold":
        statusClass = "bg-warning";
        break;
      case "complete":
        statusClass = "bg-success";
        break;
      default:
        statusClass = "";
    }
    return statusClass;
  }

  const getStatus = useCallback((selectedStatus) => {
    setStatus(selectedStatus);
  }, []);

  const handleChange = useCallback((e) => {
    setSelectDate(e.target.value);
  }, []);

  useEffect(() => {
    if (projectsData.data) {
      const projects = projectsData.data;
      setProjectListData(projects);
      const sortedUniqueList = Array.from(
        new Set(projects?.map((a) => moment(a.start_date).format("YYYY")))
      ).map((date) => {
        return projects?.find(
          (a) => moment(a.start_date).format("YYYY") === date
        );
      });
      setFilterDate(sortedUniqueList);
    }

    return () => {
      setProjectListData([]);
    };
  }, [projectsData]);

  useEffect(() => {
    const fetchProjects = async () => {
      if (usersData) {
        const response = await dispatch.projects.allProjectsOfUser({
          user_id: usersData._id,
        });
        setUserProjects(response.projects);
      }
    };

    fetchProjects();
  }, [usersData]);

  useEffect(() => {
    if (projectsData.data) {
      const projects = projectsData.data;
      setProjectListData(projects);
    }

    return () => {
      setProjectListData([]);
    };
  }, [selectDate, searchData, projectsData.data]);

  const searchSelect = (searchData) => {
    setSearchData(searchData && searchData.value);
  };

  // For Pagination
  const handlePageClick = useCallback(
    async (event) => {
      setLoading(true);
      setCurrentPage(event.selected);
      await dispatch.projects.listProjectData({
        limit: pageLimit,
        currentPage: event.selected,
        status: dataStatus,
        search: projectSearchValue,
      });

      setLoading(false);
    },
    [dataStatus]
  );

  const projectSearch = async (searchvalue) => {
    setProjectSearchValue(searchvalue);
    setLoading(true);
    setCurrentPage(0);
    await dispatch.projects.listProjectData({
      limit: pageLimit,
      currentPage: 0,
      status: dataStatus,
      search: searchvalue,
    });
    setLoading(false);
  };

  return (
    <>
      <div className="px-6 pt-8">
        <p className="text-[#252525] text-3xl font-light leading-4">Overview</p>
        <div className="max-w-full grid sm:grid-cols-2 xl:grid-cols-4 xl:w-full gap-6 overflow-x-auto pt-6 pb-7">
          <div className="grid w-full gap-4">
            <div className="border w-full pt-5 pr-28 pb-6 pl-8 border-borderColor-100 rounded-xl block relative text-left project-item-block all-projects bg-[#F3F3F3] shadow">
              {projectsData &&
              projectsData.count &&
              projectsData.count.totalcount ? (
                <div>
                  <div className="flex gap-3 items-center justify-start">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.75 7C8.55109 7 8.36032 7.07902 8.21967 7.21967C8.07902 7.36032 8 7.55109 8 7.75C8 7.94891 8.07902 8.13968 8.21967 8.28033C8.36032 8.42098 8.55109 8.5 8.75 8.5H16.25C16.4489 8.5 16.6397 8.42098 16.7803 8.28033C16.921 8.13968 17 7.94891 17 7.75C17 7.55109 16.921 7.36032 16.7803 7.21967C16.6397 7.07902 16.4489 7 16.25 7H8.75ZM7 11.75C7 11.5511 7.07902 11.3603 7.21967 11.2197C7.36032 11.079 7.55109 11 7.75 11H14.25C14.4489 11 14.6397 11.079 14.7803 11.2197C14.921 11.3603 15 11.5511 15 11.75C15 11.9489 14.921 12.1397 14.7803 12.2803C14.6397 12.421 14.4489 12.5 14.25 12.5H7.75C7.55109 12.5 7.36032 12.421 7.21967 12.2803C7.07902 12.1397 7 11.9489 7 11.75ZM9.75 15C9.55109 15 9.36032 15.079 9.21967 15.2197C9.07902 15.3603 9 15.5511 9 15.75C9 15.9489 9.07902 16.1397 9.21967 16.2803C9.36032 16.421 9.55109 16.5 9.75 16.5H16.25C16.4489 16.5 16.6397 16.421 16.7803 16.2803C16.921 16.1397 17 15.9489 17 15.75C17 15.5511 16.921 15.3603 16.7803 15.2197C16.6397 15.079 16.4489 15 16.25 15H9.75Z"
                        fill="#454545"
                      />
                      <path
                        d="M2 3.75C2 2.784 2.784 2 3.75 2H20.25C21.216 2 22 2.784 22 3.75V20.25C22 20.7141 21.8156 21.1592 21.4874 21.4874C21.1592 21.8156 20.7141 22 20.25 22H3.75C3.28587 22 2.84075 21.8156 2.51256 21.4874C2.18437 21.1592 2 20.7141 2 20.25V3.75ZM3.75 3.5C3.6837 3.5 3.62011 3.52634 3.57322 3.57322C3.52634 3.62011 3.5 3.6837 3.5 3.75V20.25C3.5 20.388 3.612 20.5 3.75 20.5H20.25C20.3163 20.5 20.3799 20.4737 20.4268 20.4268C20.4737 20.3799 20.5 20.3163 20.5 20.25V3.75C20.5 3.6837 20.4737 3.62011 20.4268 3.57322C20.3799 3.52634 20.3163 3.5 20.25 3.5H3.75Z"
                        fill="#454545"
                      />
                    </svg>
                    <h5 className=" text-[#454545]">Total Projects</h5>
                  </div>
                  <p className="pt-4 text-5xl pl-8 font-semibold text-[#454545]">
                    {projectsData &&
                      projectsData.count &&
                      projectsData.count.totalcount}
                  </p>
                </div>
              ) : (
                <Skeleton count={2} />
              )}
            </div>
          </div>
          <div className="grid w-full gap-4">
            <div className="border w-full pt-5 pr-28 pb-6 pl-8 border-borderColor-100 rounded-xl block relative text-left project-item-block all-projects bg-[#F3F3F3] shadow">
              {projectsData &&
              projectsData.count &&
              projectsData.count.ongoingcount ? (
                <div>
                  <div className="flex gap-3 items-center justify-start">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.75 7C8.55109 7 8.36032 7.07902 8.21967 7.21967C8.07902 7.36032 8 7.55109 8 7.75C8 7.94891 8.07902 8.13968 8.21967 8.28033C8.36032 8.42098 8.55109 8.5 8.75 8.5H16.25C16.4489 8.5 16.6397 8.42098 16.7803 8.28033C16.921 8.13968 17 7.94891 17 7.75C17 7.55109 16.921 7.36032 16.7803 7.21967C16.6397 7.07902 16.4489 7 16.25 7H8.75ZM7 11.75C7 11.5511 7.07902 11.3603 7.21967 11.2197C7.36032 11.079 7.55109 11 7.75 11H14.25C14.4489 11 14.6397 11.079 14.7803 11.2197C14.921 11.3603 15 11.5511 15 11.75C15 11.9489 14.921 12.1397 14.7803 12.2803C14.6397 12.421 14.4489 12.5 14.25 12.5H7.75C7.55109 12.5 7.36032 12.421 7.21967 12.2803C7.07902 12.1397 7 11.9489 7 11.75ZM9.75 15C9.55109 15 9.36032 15.079 9.21967 15.2197C9.07902 15.3603 9 15.5511 9 15.75C9 15.9489 9.07902 16.1397 9.21967 16.2803C9.36032 16.421 9.55109 16.5 9.75 16.5H16.25C16.4489 16.5 16.6397 16.421 16.7803 16.2803C16.921 16.1397 17 15.9489 17 15.75C17 15.5511 16.921 15.3603 16.7803 15.2197C16.6397 15.079 16.4489 15 16.25 15H9.75Z"
                        fill="#454545"
                      />
                      <path
                        d="M2 3.75C2 2.784 2.784 2 3.75 2H20.25C21.216 2 22 2.784 22 3.75V20.25C22 20.7141 21.8156 21.1592 21.4874 21.4874C21.1592 21.8156 20.7141 22 20.25 22H3.75C3.28587 22 2.84075 21.8156 2.51256 21.4874C2.18437 21.1592 2 20.7141 2 20.25V3.75ZM3.75 3.5C3.6837 3.5 3.62011 3.52634 3.57322 3.57322C3.52634 3.62011 3.5 3.6837 3.5 3.75V20.25C3.5 20.388 3.612 20.5 3.75 20.5H20.25C20.3163 20.5 20.3799 20.4737 20.4268 20.4268C20.4737 20.3799 20.5 20.3163 20.5 20.25V3.75C20.5 3.6837 20.4737 3.62011 20.4268 3.57322C20.3799 3.52634 20.3163 3.5 20.25 3.5H3.75Z"
                        fill="#454545"
                      />
                    </svg>
                    <h5 className=" text-[#454545]">Active Projects</h5>
                  </div>
                  <p className="pt-4 text-5xl pl-8 font-semibold text-[#454545]">
                    {projectsData &&
                      projectsData.count &&
                      projectsData.count.ongoingcount}
                  </p>
                </div>
              ) : (
                <Skeleton count={2} />
              )}
            </div>
          </div>
          <div className="grid w-full gap-4">
            <div className="border w-full pt-5 pr-28 pb-6 pl-8 border-borderColor-100 rounded-xl block relative text-left project-item-block all-projects bg-[#F3F3F3] shadow">
              {projectsData &&
              projectsData.count &&
              projectsData.count.holdcount ? (
                <div>
                  <div className="flex gap-3 items-center justify-start">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.75 7C8.55109 7 8.36032 7.07902 8.21967 7.21967C8.07902 7.36032 8 7.55109 8 7.75C8 7.94891 8.07902 8.13968 8.21967 8.28033C8.36032 8.42098 8.55109 8.5 8.75 8.5H16.25C16.4489 8.5 16.6397 8.42098 16.7803 8.28033C16.921 8.13968 17 7.94891 17 7.75C17 7.55109 16.921 7.36032 16.7803 7.21967C16.6397 7.07902 16.4489 7 16.25 7H8.75ZM7 11.75C7 11.5511 7.07902 11.3603 7.21967 11.2197C7.36032 11.079 7.55109 11 7.75 11H14.25C14.4489 11 14.6397 11.079 14.7803 11.2197C14.921 11.3603 15 11.5511 15 11.75C15 11.9489 14.921 12.1397 14.7803 12.2803C14.6397 12.421 14.4489 12.5 14.25 12.5H7.75C7.55109 12.5 7.36032 12.421 7.21967 12.2803C7.07902 12.1397 7 11.9489 7 11.75ZM9.75 15C9.55109 15 9.36032 15.079 9.21967 15.2197C9.07902 15.3603 9 15.5511 9 15.75C9 15.9489 9.07902 16.1397 9.21967 16.2803C9.36032 16.421 9.55109 16.5 9.75 16.5H16.25C16.4489 16.5 16.6397 16.421 16.7803 16.2803C16.921 16.1397 17 15.9489 17 15.75C17 15.5511 16.921 15.3603 16.7803 15.2197C16.6397 15.079 16.4489 15 16.25 15H9.75Z"
                        fill="#454545"
                      />
                      <path
                        d="M2 3.75C2 2.784 2.784 2 3.75 2H20.25C21.216 2 22 2.784 22 3.75V20.25C22 20.7141 21.8156 21.1592 21.4874 21.4874C21.1592 21.8156 20.7141 22 20.25 22H3.75C3.28587 22 2.84075 21.8156 2.51256 21.4874C2.18437 21.1592 2 20.7141 2 20.25V3.75ZM3.75 3.5C3.6837 3.5 3.62011 3.52634 3.57322 3.57322C3.52634 3.62011 3.5 3.6837 3.5 3.75V20.25C3.5 20.388 3.612 20.5 3.75 20.5H20.25C20.3163 20.5 20.3799 20.4737 20.4268 20.4268C20.4737 20.3799 20.5 20.3163 20.5 20.25V3.75C20.5 3.6837 20.4737 3.62011 20.4268 3.57322C20.3799 3.52634 20.3163 3.5 20.25 3.5H3.75Z"
                        fill="#454545"
                      />
                    </svg>
                    <h5 className=" text-[#454545]">Hold Projects</h5>
                  </div>
                  <p className="pt-4 text-5xl pl-8 font-semibold text-[#454545]">
                    {projectsData &&
                      projectsData.count &&
                      projectsData.count.holdcount}
                  </p>
                </div>
              ) : (
                <Skeleton count={2} />
              )}
            </div>
          </div>
          <div className="grid w-full gap-4">
            <div className="border w-full pt-5 pr-28 pb-6 pl-8 border-borderColor-100 rounded-xl block relative text-left project-item-block all-projects bg-[#F3F3F3] shadow">
              {projectsData &&
              projectsData.count &&
              projectsData.count.completecount ? (
                <div>
                  <div className="flex gap-3 items-center justify-start">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.75 7C8.55109 7 8.36032 7.07902 8.21967 7.21967C8.07902 7.36032 8 7.55109 8 7.75C8 7.94891 8.07902 8.13968 8.21967 8.28033C8.36032 8.42098 8.55109 8.5 8.75 8.5H16.25C16.4489 8.5 16.6397 8.42098 16.7803 8.28033C16.921 8.13968 17 7.94891 17 7.75C17 7.55109 16.921 7.36032 16.7803 7.21967C16.6397 7.07902 16.4489 7 16.25 7H8.75ZM7 11.75C7 11.5511 7.07902 11.3603 7.21967 11.2197C7.36032 11.079 7.55109 11 7.75 11H14.25C14.4489 11 14.6397 11.079 14.7803 11.2197C14.921 11.3603 15 11.5511 15 11.75C15 11.9489 14.921 12.1397 14.7803 12.2803C14.6397 12.421 14.4489 12.5 14.25 12.5H7.75C7.55109 12.5 7.36032 12.421 7.21967 12.2803C7.07902 12.1397 7 11.9489 7 11.75ZM9.75 15C9.55109 15 9.36032 15.079 9.21967 15.2197C9.07902 15.3603 9 15.5511 9 15.75C9 15.9489 9.07902 16.1397 9.21967 16.2803C9.36032 16.421 9.55109 16.5 9.75 16.5H16.25C16.4489 16.5 16.6397 16.421 16.7803 16.2803C16.921 16.1397 17 15.9489 17 15.75C17 15.5511 16.921 15.3603 16.7803 15.2197C16.6397 15.079 16.4489 15 16.25 15H9.75Z"
                        fill="#454545"
                      />
                      <path
                        d="M2 3.75C2 2.784 2.784 2 3.75 2H20.25C21.216 2 22 2.784 22 3.75V20.25C22 20.7141 21.8156 21.1592 21.4874 21.4874C21.1592 21.8156 20.7141 22 20.25 22H3.75C3.28587 22 2.84075 21.8156 2.51256 21.4874C2.18437 21.1592 2 20.7141 2 20.25V3.75ZM3.75 3.5C3.6837 3.5 3.62011 3.52634 3.57322 3.57322C3.52634 3.62011 3.5 3.6837 3.5 3.75V20.25C3.5 20.388 3.612 20.5 3.75 20.5H20.25C20.3163 20.5 20.3799 20.4737 20.4268 20.4268C20.4737 20.3799 20.5 20.3163 20.5 20.25V3.75C20.5 3.6837 20.4737 3.62011 20.4268 3.57322C20.3799 3.52634 20.3163 3.5 20.25 3.5H3.75Z"
                        fill="#454545"
                      />
                    </svg>
                    <h5 className=" text-[#454545]">Completed Projects</h5>
                  </div>
                  <p className="pt-4 text-5xl pl-8 font-semibold text-[#454545]">
                    {projectsData &&
                      projectsData.count &&
                      projectsData.count.completecount}
                  </p>
                </div>
              ) : (
                <Skeleton count={2} />
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="px-6 mt-6">
        <p className="text-[#252525] text-3xl font-light leading-4 pb-8">
          My Projects
        </p>
        <div className="grid sm:grid-cols-2 pb-8 lg:grid-cols-3 xl:grid-cols-4 gap-5 2xl:gap-16">
          {userProjects && userProjects.length > 0 ? (
            userProjects.map((project, index) => (
              <Card key={index} project={project} />
            ))
          ) : (
            <p className="text-blue-500 font-medium text-base">
              No current projects
            </p>
          )}
        </div>
      </div>

      <HeaderStrip
        projectsData={projectsData}
        page="projectPage"
        searchSelect={searchSelect}
        projectListData={projectListData}
        filterDate={filterDate}
        getStatus={getStatus}
        handleChange={handleChange}
        dataStatus={dataStatus}
        projectSearch={projectSearch}
      />

      <div className="px-6 max-w-full overflow-x-scroll">
        <div className="bg-white rounded flex flex-col w-auto">
          <div className="grid sm:grid-cols-2 pb-8 lg:grid-cols-3 xl:grid-cols-4 gap-5 2xl:gap-16">
            {/* Project listing */}
            {!loading && projectListData?.length > 0 ? (
              projectListData.map((project, index) => (
                <Card project={project} />
              ))
            ) : (
              <Skeleton count={10} />
            )}
          </div>
        </div>

        {/* Pagination */}
        {projectsData &&
          projectsData.count &&
          projectsData.count.totalcount &&
          projectsData.count.totalcount > 10 && (
            <Pagination
              handlePageClick={handlePageClick}
              pageCount={pageCount}
              pageLimit={pageLimit}
              currentPage={currentPage}
            />
          )}
      </div>
    </>
  );
};

export default Project;
