import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import AddResources from "../../containers/pages/projects/AddResources";
import ProjectCreate from "../../containers/pages/projects/ProjectCreate";
import ModalLayout from "../Common/ModalLayout";
import { Tooltip as ReactTooltip } from "react-tooltip";

const Header = ({
  logOut,
  projectHeader,
  projectname,
  tabTitle,
  setStatus,
  status,
  openMenu,
  setOpenMenu,
}) => {
  const [openDrop, setOpenDrop] = useState(false);
  const usersData = useSelector((state) => state.auth.userInfoData);
  const dispatch = useDispatch();
  const [stepOne, setStepOne] = useState(true);
  const [showMenu, setShowMenu] = useState(false);
  const [open, SetOpenModal] = useState(false);
  const [projectSearch, setProjectSearch] = useState("");
  const [projectSearchData, setProjectSearchData] = useState("");
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const nextStep = (stepComplete) => {
    if (!stepComplete) {
      setStepOne(false);
    } else {
      setStepOne(true);
    }
  };

  const profileDrop = () => {
    setOpenDrop(!openDrop);
  };

  const handleModal = () => {
    if (!open) {
      setStepOne(true);
    }
    SetOpenModal(!open);
  };

  useEffect(() => {
    searchProjects(projectSearch);
  }, [projectSearch]);

  const searchProjects = async (search) => {
    if (search.length < 2) {
      setTimeout(function () {
        if (search.length < 2) {
          setProjectSearchData([]);
        }
      }, 900);
      setProjectSearchData([]);
      return;
    } else {
      const serachData = await dispatch.projects.ProjectTitleSearch({
        search: search,
      });
      setProjectSearchData(serachData.data);
    }
  };
  const pageRedirectAfterSearch = async () => {
    setProjectSearch("");
  };

  return (
    <div className="fixed w-full z-50">
      <div className="h-full md:h-16 flex items-center bg-white border-b-[1px] shadow-200 py-4 px-3 md:px-6  relative z-10">
        {!showMenu ? (
          <div
            className="block md:hidden mr-2 sm:mr-4"
            onClick={() => {
              projectHeader ? setOpenMenu(!openMenu) : setShowMenu(true);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill=""
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="#000000"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
              />
            </svg>
          </div>
        ) : (
          <div
            className="block md:hidden mr-2"
            onClick={() => setShowMenu(false)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="#000000"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </div>
        )}

        <div className="flex items-center w-full">
          <div className="flex items-center justify-start">
            <div className="mr-2 lg:mr-9">
              <svg
                width="28"
                height="30"
                viewBox="0 0 28 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.5 3.7568V26.3561H24.5V3.7568H3.5ZM2 0.52832H26C26.3978 0.52832 26.7794 0.698391 27.0607 1.00112C27.342 1.30385 27.5 1.71444 27.5 2.14256V27.9704C27.5 28.3985 27.342 28.8091 27.0607 29.1118C26.7794 29.4145 26.3978 29.5846 26 29.5846H2C1.60218 29.5846 1.22064 29.4145 0.93934 29.1118C0.658035 28.8091 0.5 28.3985 0.5 27.9704V2.14256C0.5 1.71444 0.658035 1.30385 0.93934 1.00112C1.22064 0.698391 1.60218 0.52832 2 0.52832ZM15.5 11.828V23.1276H12.5V11.828H6.5V8.59951H21.5V11.828H15.5Z"
                  fill="#4D4D4D"
                />
              </svg>
            </div>
          </div>

          {/* menu bar */}
          <div
            className={`bg-primary font-semibold opacity-80 w-full text-white flex h-screen flex-col space-y-4 absolute top-[74px] left-0 pl-3 pt-3 z-50 transition-all duration-500 ease-in-out transform ${
              showMenu
                ? "opacity-100 translate-x-0"
                : "opacity-0 -translate-x-full pointer-events-none"
            }`}
          >
            <NavLink
              activeclassname="is-active"
              onClick={() => setShowMenu(false)}
              className="py-2 text-white text-sm transition duration-150 ease-out mr-9"
              to="/projects"
            >
              Project
            </NavLink>
            <NavLink
              activeclassname="is-active"
              onClick={() => setShowMenu(false)}
              className="py-2 text-sm transition duration-150 ease-out mr-9"
              to="/availability"
            >
              Availability
            </NavLink>
            <NavLink
              activeclassname="is-active"
              onClick={() => setShowMenu(false)}
              className="py-2 text-sm transition duration-150 ease-out mr-9"
              to="/people"
            >
              People
            </NavLink>
            <NavLink
              activeclassname="is-active"
              onClick={() => setShowMenu(false)}
              className="py-2 text-sm transition duration-150 ease-out mr-9"
              to="/teamwork-time"
            >
              Teamwork
            </NavLink>
            <NavLink
              activeclassname="is-active"
              onClick={() => setShowMenu(false)}
              className="py-2 text-sm transition duration-150 ease-out mr-9"
              to="/tasks-assigned"
            >
              Task Assigned
            </NavLink>
          </div>

          <div className="flex md:flex-row items-center w-full justify-start">
            {projectHeader ? (
              <div className="flex items-center w-full justify-start">
                <NavLink
                  className=" mr-8 text-black font-bold md:flex items-center gap-2"
                  to="/projects"
                >
                  {" "}
                  <svg
                    width="8"
                    height="12"
                    viewBox="0 0 8 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6 0L7.41 1.41L2.83 6L7.41 10.59L6 12L2.86102e-06 6L6 0Z"
                      fill="black"
                    />
                  </svg>
                  <p className="hidden md:flex">{projectname}</p>
                </NavLink>
                <header className="px-2 hidden md:flex">
                  {tabTitle &&
                    tabTitle.map((item) => (
                      <Link
                        key={item.id}
                        to={`/projects/${projectname}/${item.status}`}
                      >
                        <button
                          className={`${
                            item?.status === status
                              ? "!text-black"
                              : "border-transparent text-gray-500"
                          }   mr-5 font-title text-sm  text-balck font-normal`}
                          onClick={() => setStatus(item.status)}
                        >
                          {item.label}
                        </button>
                      </Link>
                    ))}
                </header>
              </div>
            ) : (
              <div className="hidden main-menu md:flex items-center w-full justify-start gap-3 xl:gap-6">
                <NavLink
                  activeclassname="is-active"
                  className="py-2 text-sm transition duration-150 ease-out"
                  to="/projects"
                >
                  Project
                </NavLink>
                <NavLink
                  activeclassname="is-active"
                  className={`py-2 text-sm transition duration-150 ease-out `}
                  to="/availability"
                >
                  Availability
                </NavLink>
                <NavLink
                  activeclassname="is-active"
                  className={`py-2 text-sm transition duration-150 ease-out`}
                  to="/people"
                >
                  People
                </NavLink>
                <NavLink
                  activeclassname="is-active"
                  className={`py-2 text-sm transition duration-150 ease-out`}
                  to="/teamwork-time"
                >
                  Teamwork
                </NavLink>
                <NavLink
                  activeclassname="is-active"
                  className={`py-2 w-max text-sm transition duration-150 ease-out`}
                  to="/tasks-assigned"
                >
                  Task Assigned
                </NavLink>
              </div>
            )}

            <div className="flex items-center w-full justify-end">
              {/* <button type="button" className="mr-5">
                            <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.67541 0.0297451C4.71576 0.154781 3.66545 0.548645 2.88397 1.07692C1.33352 2.1241 0.311353 3.74957 0.0487772 5.58135C-0.00748913 5.9846 -0.0168669 6.87548 0.0300217 7.27872C0.264465 9.16364 1.29601 10.8454 2.88397 11.9176C3.69671 12.4677 4.74076 12.8491 5.76293 12.971C6.16618 13.0179 7.23836 12.9898 7.59472 12.9241C8.48872 12.7553 9.32647 12.4271 10.0298 11.9676L10.3236 11.7769L12.4367 13.8869C13.5996 15.0497 14.5655 16 14.5811 16C14.6218 16 16.0034 14.6183 16.0034 14.5777C16.0034 14.5621 15.0531 13.5962 13.8903 12.4333L11.7803 10.3202L11.9679 10.0264C12.4492 9.28868 12.7556 8.50408 12.9275 7.59131C12.9932 7.23496 13.0213 6.16277 12.9744 5.75953C12.7493 3.8496 11.7178 2.15849 10.1173 1.07692C9.31709 0.536141 8.27929 0.151655 7.28212 0.026619C6.97266 -0.0108929 5.98487 -0.00776672 5.67541 0.0297451ZM6.93202 2.04283C7.89793 2.10222 9.0045 2.63988 9.72033 3.39009C10.5487 4.2591 10.9707 5.30627 10.9707 6.49724C10.9707 7.23808 10.8425 7.79137 10.5206 8.45094C10.0329 9.45123 9.24832 10.1921 8.2199 10.6328C7.09144 11.1142 5.73167 11.0861 4.6251 10.5578C4.23437 10.3702 4.06557 10.2671 3.73422 10.0108C2.86522 9.33869 2.25567 8.30714 2.07749 7.20057C2.02122 6.86923 2.01497 6.18153 2.06499 5.86269C2.33694 4.03716 3.68421 2.56798 5.47535 2.13661C5.78169 2.06158 6.38186 1.99594 6.56317 2.0147C6.60693 2.02095 6.7726 2.03345 6.93202 2.04283Z" fill="#CCCCCC" />
                            </svg>
                        </button> */}
              <div className={`relative mr-3 ${!isSearchOpen ? "w-1/2" : ""}`}>
                {/* search */}
                <div
                  data-tooltip-id="search"
                  className={`absolute top-2/4 -translate-y-2/4 ${
                    isSearchOpen ? "left-[-25px] sm:left-[-25px] " : "right-3"
                  } z-10`}
                >
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() => setIsSearchOpen(!isSearchOpen)}
                    className="cursor-pointer"
                  >
                    <path
                      d="M4.45028 0.686507C3.81051 0.769865 3.11031 1.03244 2.58932 1.38463C1.55569 2.08275 0.874241 3.16639 0.69919 4.38758C0.661679 4.65641 0.655427 5.25033 0.686686 5.51916C0.842982 6.77577 1.53068 7.89693 2.58932 8.61172C3.13115 8.97849 3.82718 9.23273 4.50863 9.31401C4.77746 9.34527 5.49225 9.32651 5.72982 9.28275C6.32582 9.17022 6.88432 8.9514 7.3532 8.64506L7.54909 8.51794L8.95783 9.9246C9.73306 10.6998 10.377 11.3333 10.3874 11.3333C10.4145 11.3333 11.3356 10.4122 11.3356 10.3852C11.3356 10.3747 10.7021 9.7308 9.92686 8.95557L8.52021 7.54683L8.64524 7.35094C8.96617 6.85913 9.1704 6.33606 9.28501 5.72755C9.32877 5.48998 9.34753 4.77519 9.31627 4.50636C9.16623 3.23308 8.47853 2.10567 7.41155 1.38463C6.87806 1.02411 6.1862 0.767781 5.52142 0.684423C5.31511 0.659416 4.65659 0.6615 4.45028 0.686507ZM5.28802 2.02856C5.93196 2.06816 6.66967 2.4266 7.14689 2.92674C7.69914 3.50607 7.98047 4.20419 7.98047 4.99817C7.98047 5.49207 7.89503 5.86092 7.68038 6.30063C7.35529 6.96749 6.83222 7.46139 6.1466 7.75522C5.3943 8.07615 4.48779 8.05739 3.75007 7.70521C3.48958 7.58017 3.37705 7.5114 3.15615 7.34052C2.57682 6.89247 2.17045 6.20477 2.05167 5.46706C2.01415 5.24616 2.00999 4.7877 2.04333 4.57513C2.22463 3.35812 3.12281 2.37866 4.31691 2.09108C4.52113 2.04107 4.92125 1.9973 5.04212 2.00981C5.07129 2.01398 5.18174 2.02231 5.28802 2.02856Z"
                      fill="#54585D"
                    />
                  </svg>
                </div>

                <div>
                  <input
                    className={`w-full border shadow-sm rounded-md p-[6px] text-xs transition-all duration-500 ease-in-out transform focus:border-blue-700 border-blue-600 ${
                      isSearchOpen
                        ? "opacity-100 translate-x-0"
                        : "opacity-0 -translate-x-full pointer-events-none"
                    }`}
                    type="text"
                    value={projectSearch}
                    placeholder="Search Project"
                    onChange={(evt) => {
                      setProjectSearch(evt.target.value);
                    }}
                  />
                </div>

                {/* add project */}
                <div>
                  {projectSearchData && projectSearchData.length ? (
                    <div className="absolute rounded-md bg-white transition duration-300 ease-out drop-shadow-md left-0 top-full mt-0.5  right-0 py-2">
                      {projectSearchData &&
                        projectSearchData.length > 0 &&
                        projectSearchData.map((item, ind) => {
                          return (
                            <Link
                              key={ind}
                              onClick={() => pageRedirectAfterSearch()}
                              className="px-4 py-1 text-sm block hover:bg-secondary-blue-300  transition duration-300  hover:text-black"
                              to={`/projects/${item.name}/overview`}
                            >
                              {item.name}
                            </Link>
                          );
                        })}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <button
                data-tooltip-id="add-project"
                onClick={() => handleModal()}
                type="button"
                className={`text-greyColor-900 text-3xl mr-5 -mt-[3px] hover:text-primary-navyBlue ${
                  isSearchOpen ? "hidden lg:block" : ""
                }`}
              >
                <svg
                  width="26"
                  height="28"
                  viewBox="0 0 26 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    y="1.14233"
                    width="26"
                    height="26"
                    rx="13"
                    fill="#A0DEFF"
                  />
                  <path
                    d="M7.60001 14.7044V13.8037H12.4892V8.3999H13.3041V13.8037H18.1932V14.7044H13.3041V20.1082H12.4892V14.7044H7.60001Z"
                    fill="white"
                  />
                </svg>
              </button>

              {/* <button type="button" className="mr-6 cursor-not-allowed ">
                            <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M10.1329 2.09315L8.38218 0H0V16H20V2.09315H10.1329ZM18 4.09315H9.19831L7.44762 2H2V14H18V4.09315Z" fill="#CCCCCC" />
                            </svg>
                        </button> */}

              <div
                data-tooltip-id="profile"
                className="relative flex gap-3 items-center justify-center pl-2 pr-4"
              >
                <div
                  onClick={() => profileDrop()}
                  className="text-white text-sm flex items-center justify-center bg-primary-navyBlue border border-primary-navyBlue w-8 h-10 min-w-[40px] rounded-full cursor-pointer uppercase"
                >
                  {usersData?.first_name &&
                    usersData.first_name.substring(0, 1)}
                  {usersData.last_name
                    ? usersData.last_name.substring(0, 1)
                    : usersData.first_name.substring(1, 2)}
                </div>
                <div className="hidden sm:block md:hidden lg:block">
                  <p className="w-max text-xs font-medium">
                    {usersData?.first_name} {usersData?.last_name}
                  </p>
                  <p className="w-max text-xs text-[#8E8E8E] font-light">
                    {usersData?.designation?.name}
                  </p>
                </div>
                <div
                  className={`flex-col absolute top-12 right-0 w-40 rounded bg-white transition duration-300 ease-out drop-shadow-md z-20 ${
                    openDrop ? "flex" : "hidden"
                  }`}
                >
                  <Link
                    className="px-3 py-2 text-sm transition duration-150 ease-out hover:bg-primary-400 hover:bg-opacity-50"
                    to="/profile"
                  >
                    Profile
                  </Link>
                  <Link
                    className="px-3 py-2 text-sm transition duration-150 ease-out hover:bg-primary-400 hover:bg-opacity-50"
                    to="/change-password"
                  >
                    Change Password
                  </Link>
                  <div
                    onClick={logOut}
                    className="cursor-pointer px-3 py-2 text-sm transition duration-150 ease-out hover:bg-primary-400 hover:bg-opacity-50"
                  >
                    Logout
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ModalLayout closeModal={handleModal} open={open}>
          {stepOne ? (
            <ProjectCreate nextStep={nextStep} />
          ) : (
            <AddResources closeModal={handleModal} />
          )}
        </ModalLayout>
      </div>
      <ReactTooltip
        id="add-project"
        style={{
          backgroundColor: "white",
          color: "black",
          boxShadow: "1px 2px 3px 0px rgba(128, 128, 128, 0.5)",
          zIndex: "30",
        }}
        place="bottom"
        content="Add Project"
      />
      <ReactTooltip
        id="search"
        style={{
          backgroundColor: "white",
          color: "black",
          boxShadow: "1px 2px 3px 0px rgba(128, 128, 128, 0.5)",
          zIndex: "30",
        }}
        place="bottom"
      />
      <ReactTooltip
        id="search-close"
        style={{
          backgroundColor: "white",
          color: "black",
          boxShadow: "1px 2px 3px 0px rgba(128, 128, 128, 0.5)",
          zIndex: "30",
        }}
        place="bottom"
        content="Close"
      />
      <ReactTooltip
        id="profile"
        style={{
          backgroundColor: "white",
          color: "black",
          boxShadow: "1px 2px 3px 0px rgba(128, 128, 128, 0.5)",
          zIndex: "30",
        }}
        place="bottom"
        content="Profile"
      />
    </div>
  );
};

const mapState = (state) => ({
  userInfo: state.auth.userInfo,
});

const mapDispatch = (dispatch) => ({
  logOut: dispatch.auth.logOut,
});

export default connect(mapState, mapDispatch)(Header);
