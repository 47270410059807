import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Loader from "../Utilities/Loader";

const LoginSchema = Yup.object().shape({
  email: Yup.string().required("Email is required"),
  password: Yup.string().required("Password is required"),
});

const NewLogin = ({ userInfo, loginUser }) => {
  const [isUpdateLoading, setUpdateLoading] = useState(false);
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const loginSubmit = async (values) => {
    setUpdateLoading(true);
    await loginUser({
      email: values.email,
      password: values.password,
    })
      .then(({ message }) => {
        setUpdateLoading(false);
        setError(message);
        setUpdateLoading(false);
      })
      .catch(({ message }) => {
        setError(message);
        setUpdateLoading(false);
      });
  };

  return (
    <div className="flex flex-col md:flex-row p-0 md:p-10 sm:justify-between sm:items-center w-full  md:h-screen xl:gap-16 xl:mx-auto xl:max-w-[90%]">
      {/* Banner section and it's not visible in the mobile view */}
      <div className="hidden relative xl:static md:flex pr-4 md:flex-col sm:items-center sm:justify-center h-full w-full">
        <img
          className="object-cover w-full absolute md:right-[30%] lg:right-[20%] xl:static md:h-[80%] xl:h-full rounded-e-[70px] xl:rounded-none xl:object-fill p-4"
          src="/images/Login_image.png"
          alt="login banner"
        />
      </div>
      <div className="md:hidden w-full mb-10">
        <img
          className="w-full h-auto object-fill"
          src="/images/Vector_ 2.png"
          alt="login banner"
        />
      </div>

      {/* Login section */}
      <div className="h-full flex gap-6 p-10 md:p-0 flex-col items-start md:justify-between w-full md:w-[1000px] ">
        <p className="text-base font-thin opacity-80 ">toobler pm</p>
        <div>
          <h1 className="text-2xl font-semibold text-black">Welcome!</h1>
          <p className="text-xs">Login to get started using Toobler PM</p>
        </div>
        <Formik
          initialValues={{ email: "", password: "" }}
          validationSchema={LoginSchema}
          onSubmit={loginSubmit}
        >
          {({ isSubmitting }) => (
            <Form className="w-full flex flex-col">
              <div className="mb-1 ">
                <label className="block text-sm font-medium text-black mb-2">
                  Email
                </label>
                <Field
                  type="email"
                  name="email"
                  className="text-sm p-2 xl:p-4 pl-4 xl:pl-6 w-full border border-gray-300 rounded-xl focus:outline-none focus:border-black"
                  placeholder="Email"
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="text-red-500 text-xs mt-1 ml-2 xl:ml-6"
                />
              </div>
              <div className="mb-2">
                <label className="block text-sm font-medium text-black mb-2 mt-5">
                  Password
                </label>
                <div className="relative w-full">
                  <Field
                    type={showPassword ? "text" : "password"}
                    name="password"
                    className="text-sm p-2 xl:p-4 pl-4 xl:pl-6 w-full border border-gray-300 rounded-xl focus:outline-none focus:border-black"
                    placeholder="Password"
                  />

                  <svg
                    onClick={() => setShowPassword(!showPassword)}
                    className={`cursor-pointer absolute transition-transform right-6 transform -translate-y-1/2 ${
                      showPassword ? "transform rotate-180 top-7" : "top-1/2"
                    } `}
                    width="15"
                    height="11"
                    viewBox="0 0 15 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.83936 10.0865L4.63186 9.698L5.12373 7.49375C4.38748 7.16787 3.7032 6.69299 3.10061 6.08975L1.75499 7.70525L0.871235 6.64475L2.21686 5.02925C1.45691 3.93707 0.94629 2.62682 0.734985 1.22675L1.96499 0.957497C2.20089 2.51285 2.88416 3.91955 3.89569 4.9324C4.90722 5.94525 6.18287 6.50003 7.5003 6.50003C8.81772 6.50003 10.0934 5.94525 11.1049 4.9324C12.1164 3.91955 12.7997 2.51285 13.0356 0.957497L14.2656 1.226C14.0542 2.6264 13.5434 3.93691 12.7831 5.02925L14.1294 6.64475L13.2456 7.70525L11.8994 6.08975C11.2968 6.69298 10.6125 7.16785 9.87623 7.49375L10.3687 9.69874L9.16123 10.0865L8.66873 7.8815C7.89521 8.04061 7.10476 8.04061 6.33123 7.8815L5.83936 10.0865Z"
                      fill="#434343"
                    />
                  </svg>
                </div>
                <ErrorMessage
                  name="password"
                  component="div"
                  className="text-red-500 text-xs mt-1 ml-2 xl:ml-6"
                />
                <div className="flex justify-end mt-2">
                  <Link
                    to="/forgot-password"
                    className="text-xs text-black hover:text-opacity-75 transition delay-150 duration-150 ease-linear font-medium"
                  >
                    Forgot password?
                  </Link>
                </div>
              </div>
              <button
                type="submit"
                className="w-full text-base md:text-base bg-black text-white py-2 xl:py-4 rounded-xl font-semibold mt-12"
                disabled={isSubmitting || isUpdateLoading}
              >
                {isUpdateLoading ? (
                  <div className="flex justify-center items-center">
                    <p className="text-sm text-title">{`${
                      isUpdateLoading ? "Loading..." : "No data"
                    }`}</p>
                  </div>
                ) : (
                  "Login"
                )}
              </button>
              {error && (
                <p className="text-red-500 mb-4 mt-2 ml-4 text-sm">{error}</p>
              )}
            </Form>
          )}
        </Formik>
        <div className="w-full flex items-center justify-center">
          <p className=" text-gray-400 text-[10px]">
            2024 Toobler PM. All Rights Reserved
          </p>
        </div>
      </div>
    </div>
  );
};

const mapState = (state) => ({
  userInfo: state.auth.userInfo,
});

const mapDispatch = (dispatch) => ({
  loginUser: dispatch.auth.loginUser,
});

export default connect(mapState, mapDispatch)(NewLogin);
